<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <div class="d-flex flex-column align-items-start">
              <h4 class="card-title">
                <b>MIS CAPACITACIONES</b>
              </h4>
            </div>
          </template>
          <div class="px-5 py-3">
            <!-- linar-style cursor glow -->
            <CardTable :data="dataCards" />
          </div>
          <div class="px-4 mt-3">
            <table
              id="datatable_misCapacitaciones"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Fin</th>
                  <th>Duración</th>
                  <th>Más Detalles</th>
                  <th>Asistencia</th>
                  <th>Estado Capacitación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listaItems" :key="item.id">
                  <td
                    class="text-truncate"
                    style="max-width: 200px"
                    v-b-tooltip="{
                      title: `${item.planRegistroCapacitaciones.nombre}`,
                      placement: 'top',
                      variant: 'info',
                    }"
                  >
                    {{ item.planRegistroCapacitaciones.nombre }}
                  </td>
                  <td
                    class="text-truncate"
                    style="max-width: 200px"
                    v-b-tooltip="{
                      title: `${item.planRegistroCapacitaciones.descripcion}`,
                      placement: 'top',
                      variant: 'info',
                    }"
                  >
                    {{ item.planRegistroCapacitaciones.descripcion }}
                  </td>
                  <td>
                    {{
                      item.planRegistroCapacitaciones.fechaInicio | formatDate
                    }}
                  </td>
                  <td>
                    {{ item.planRegistroCapacitaciones.fechaFin | formatDate }}
                  </td>
                  <td>{{ item.planRegistroCapacitaciones.duracion }} h</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <b-button
                        class="d-flex"
                        v-b-modal.detailsCap
                        @click="filterData(item.id)"
                      >
                        <i class="fas fa-eye"></i
                      ></b-button>
                    </div>
                  </td>
                  <td>
                    <template>
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-form-checkbox
                          class="mb-3"
                          v-model="item.confirmacionAsistente"
                          name="check-button"
                          size="lg"
                          :disabled="
                            item.planRegistroCapacitaciones
                              .estadoCapacitacion == 1
                          "
                          switch
                          @change="toggleConfirmacion(index, item)"
                        >
                        </b-form-checkbox>
                        <i
                          v-if="isConfirmAsistenciaMap[item.id]"
                          class="fas fa-spinner fa-spin-pulse"
                          style="font-size: 1.2rem"
                        ></i>
                      </div>
                    </template>
                  </td>
                  <td>
                    <h4>
                      <b-badge
                        :variant="`${
                          item.planRegistroCapacitaciones.estadoCapacitacion &&
                          item.planRegistroCapacitaciones.disponibilidad
                            ? 'success'
                            : !item.planRegistroCapacitaciones
                                .estadoCapacitacion &&
                              item.planRegistroCapacitaciones.disponibilidad
                            ? 'warning'
                            : 'danger'
                        } `"
                      >
                        <i
                          :class="`fas ${
                            item.planRegistroCapacitaciones
                              .estadoCapacitacion &&
                            item.planRegistroCapacitaciones.disponibilidad
                              ? 'fa-check'
                              : !item.planRegistroCapacitaciones
                                  .estadoCapacitacion &&
                                item.planRegistroCapacitaciones.disponibilidad
                              ? 'fa-clock'
                              : 'fa-ban'
                          }`"
                        ></i
                        >&nbsp;
                        {{
                          item.planRegistroCapacitaciones.estadoCapacitacion &&
                          item.planRegistroCapacitaciones.disponibilidad
                            ? "Finalizada"
                            : !item.planRegistroCapacitaciones
                                .estadoCapacitacion &&
                              item.planRegistroCapacitaciones.disponibilidad
                            ? "Pendiente"
                            : "Cancelada"
                        }}
                      </b-badge>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- modal cargar archivos -->
          <b-modal
            title="Cargar certificado"
            id="fileUpload"
            size="md"
            hide-footer
          >
            <FileUploadAndPreviewPdf
              :data="listaItems"
              :item="dataFilterDetails"
              :asistente="dataFilterDetails.asistente"
              folders="users|certificados_capacitacion"
            />
          </b-modal>

          <!-- modal ver mas datalles -->
          <b-modal
            title="Detalles Capacitación"
            id="detailsCap"
            v-model="modalShowDetailsCap"
            body-bg-variant="dark"
            header-bg-variant="dark"
            footer-bg-variant="dark"
            size="xl"
            hide-footer
            hide-header
            body-class="body-modal-custom"
          >
            <div>
              <b-card bg-variant="dark">
                <div class="row flex-column-reverse flex-lg-row">
                  <!-- data info capacitacion -->
                  <div class="col-12 col-lg">
                    <!-- alert capacitacion cancelada -->
                    <b-alert
                      v-if="
                        !dataFilterDetails.planRegistroCapacitaciones
                          .disponibilidad
                      "
                      show
                      variant="danger"
                      class="overflow-hidden position-relative"
                    >
                      <!-- icon ban -->
                      <i
                        class="fas fa-ban position-absolute"
                        :style="`
                          fontSize: ${
                            dataFilterDetails.planRegistroCapacitaciones
                              .motivoCancelacion.length > 200
                              ? '10em'
                              : '5em'
                          };
                          right: ${
                            dataFilterDetails.planRegistroCapacitaciones
                              .motivoCancelacion.length > 200
                              ? '-1rem'
                              : '-.5rem'
                          };
                          bottom: ${
                            dataFilterDetails.planRegistroCapacitaciones
                              .motivoCancelacion.length > 200
                              ? '-1rem'
                              : '-.5rem'
                          };
                          opacity: 20%;
                          `"
                      ></i>
                      <!-- motivo cancelacion -->
                      <div class="d-flex flex-column">
                        <span class="font-weight-bold text-danger"
                          >Motivo cancelación
                        </span>
                        <span
                          v-b-tooltip="{
                            title: `${dataFilterDetails.planRegistroCapacitaciones.motivoCancelacion}`,
                            placement: 'bottom',
                            variant: 'danger',
                          }"
                          class="text-dark"
                          style="font-size: 1.2rem"
                          >{{
                            dataFilterDetails.planRegistroCapacitaciones
                              .motivoCancelacion.length > 200
                              ? dataFilterDetails.planRegistroCapacitaciones.motivoCancelacion.slice(
                                  0,
                                  200
                                ) + "..."
                              : dataFilterDetails.planRegistroCapacitaciones
                                  .motivoCancelacion
                          }}</span
                        >
                      </div>
                    </b-alert>

                    <div class="d-flex align-items-center">
                      <div
                        style="
                          background-color: #df122e;
                          width: 7px;
                          border-radius: 0 20px 0 20px;
                          height: 30px;
                        "
                      ></div>
                      <h4
                        v-b-tooltip="{
                          title: `${dataFilterDetails.planRegistroCapacitaciones?.nombre}`,
                          placement: 'bottom',
                          variant: 'info',
                        }"
                        style="
                          font-size: 1.5rem;
                          font-weight: bolder;
                          margin-left: 5px;
                          max-width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{
                          dataFilterDetails.planRegistroCapacitaciones?.nombre
                        }}
                      </h4>
                    </div>
                    <!-- access info -->
                    <div
                      class="d-flex align-items-center flex-wrap mt-3"
                      style="gap: 1.5rem"
                    >
                      <!-- duracion -->
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <i class="far fa-clock" style="font-size: 1.5rem"></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50"
                            >Duración</small
                          >
                          <span style="font-weight: bold; font-size: 1.1rem"
                            >{{
                              dataFilterDetails.planRegistroCapacitaciones
                                ?.duracion
                            }}
                            h</span
                          >
                        </div>
                      </div>

                      <!-- divisor -->
                      <div
                        style="
                          width: 1px;
                          height: 20px;
                          background-color: #102e5f;
                        "
                      ></div>

                      <!-- fecha inicio -->
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <i
                            class="far fa-calendar-check"
                            style="font-size: 1.5rem"
                          ></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50"
                            >Fecha inicio</small
                          >
                          <span
                            style="font-size: 1.1rem"
                            class="font-weight-bold"
                          >
                            {{ calcularTextoFecha() }}</span
                          >
                        </div>
                      </div>

                      <!-- divisor -->
                      <div
                        style="
                          width: 1px;
                          height: 20px;
                          background-color: #102e5f;
                        "
                      ></div>
                      <!-- modalidad -->
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <i
                            class="fas fa-graduation-cap"
                            style="font-size: 1.5rem"
                          ></i>
                        </div>
                        <div class="d-flex flex-column text-white-50">
                          <small class="font-weight-bold">Modalidad</small>
                          <span style="font-weight: bold; font-size: 1.1rem">{{
                            dataFilterDetails.planRegistroCapacitaciones
                              .modalidadCapacitacion.descripcion
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- descripcion -->
                    <div class="my-3">
                      <p
                        class="text-white-50"
                        style="font-size: 1rem"
                        v-b-tooltip="{
                          title: `${dataFilterDetails.planRegistroCapacitaciones?.descripcion}`,
                          placement: 'bottom',
                          variant: 'info',
                        }"
                      >
                        {{
                          dataFilterDetails.planRegistroCapacitaciones
                            ?.descripcion.length > 320
                            ? dataFilterDetails.planRegistroCapacitaciones?.descripcion.slice(
                                0,
                                320
                              ) + "..."
                            : dataFilterDetails.planRegistroCapacitaciones
                                ?.descripcion
                        }}
                      </p>
                    </div>
                    <!-- areas capacitacion -->
                    <div class="d-flex flex-wrap" style="gap: 0.5rem">
                      <small
                        class="rounded p-1 text-primary border"
                        v-for="area in dataFilterDetails?.areasCapacitacion"
                        :key="area.id"
                        >{{ area.descripcion }}</small
                      >
                    </div>

                    <!-- otros datos -->
                    <div
                      class="d-flex align-items-center mt-4 flex-wrap"
                      style="gap: 2rem"
                    >
                      <!-- instructor  -->
                      <div class="d-flex align-items-center">
                        <i class="far fa-user mr-2" style="font-size: 1rem"></i>
                        <div
                          class="d-flex flex-column font-weight-bold"
                          v-b-tooltip.hover="{ variant: 'info' }"
                          :title="
                            dataFilterDetails.planRegistroCapacitaciones
                              .nombreInstructor
                          "
                        >
                          <small class="text-white-50">Instructor</small>
                          <span class="">{{
                            dataFilterDetails.planRegistroCapacitaciones
                              .nombreInstructor.length > 20
                              ? dataFilterDetails.planRegistroCapacitaciones.nombreInstructor.slice(
                                  0,
                                  20
                                ) + "..."
                              : dataFilterDetails.planRegistroCapacitaciones
                                  .nombreInstructor
                          }}</span>
                        </div>
                      </div>
                      <!-- empresa  -->
                      <div class="d-flex align-items-center">
                        <i
                          class="far fa-building mr-2"
                          style="font-size: 1rem"
                        ></i>
                        <div
                          class="d-flex flex-column font-weight-bold"
                          v-b-tooltip.hover="{ variant: 'info' }"
                          :title="
                            dataFilterDetails.planRegistroCapacitaciones
                              .empresaCapacitadora
                          "
                        >
                          <small class="text-white-50">Empresa</small>
                          <span>{{
                            dataFilterDetails.planRegistroCapacitaciones
                              .empresaCapacitadora.length > 20
                              ? dataFilterDetails.planRegistroCapacitaciones.empresaCapacitadora.slice(
                                  0,
                                  20
                                ) + "..."
                              : dataFilterDetails.planRegistroCapacitaciones
                                  .empresaCapacitadora
                          }}</span>
                        </div>
                      </div>
                      <!-- certificado  -->
                      <div class="d-flex align-items-center">
                        <i
                          class="fas fa-certificate mr-2"
                          style="font-size: 1rem"
                        ></i>
                        <div
                          class="d-flex flex-column font-weight-bold"
                          v-b-tooltip.hover="{ variant: 'info' }"
                          :title="
                            dataFilterDetails.planRegistroCapacitaciones
                              .certificacion
                              ? 'Esta capacitación incluye certificación'
                              : 'Esta capacitación NO incluye certificación'
                          "
                        >
                          <small class="text-white-50"
                            >Certificado finalización</small
                          >
                          <span>{{
                            dataFilterDetails.planRegistroCapacitaciones
                              .certificacion
                              ? "Sí, lo incluye"
                              : "No incluye"
                          }}</span>
                        </div>
                      </div>
                      <!-- uploadCert  -->
                      <div
                        v-if="
                          dataFilterDetails.planRegistroCapacitaciones
                            .certificacion
                        "
                        class="d-flex align-items-center"
                      >
                        <div class="position-relative">
                          <i
                            class="fas fa-file-arrow-up mr-2"
                            style="font-size: 1rem"
                          ></i>
                          <i
                            :class="`fas ${
                              dataFilterDetails.estadoUploadCertificado
                                ? 'fa-check-circle text-success'
                                : 'fa-warning text-warning'
                            } position-absolute`"
                            style="font-size: 12px; left: -7px; top: -5px"
                          ></i>
                        </div>
                        <div class="d-flex flex-column font-weight-bold">
                          <small class="text-white-50">Certificado</small>
                          <span
                            >{{
                              dataFilterDetails.estadoUploadCertificado
                                ? "Subido"
                                : "Pendiente"
                            }}<i
                              v-if="
                                dataFilterDetails.planRegistroCapacitaciones
                                  .estadoCapacitacion
                              "
                              v-b-modal.fileUpload
                              class="far fa-folder-open ml-2 zoom"
                            ></i
                          ></span>
                        </div>
                      </div>
                    </div>

                    <!-- comentar y calificar  -->
                    <div class="mt-3 d-flex flex-wrap" style="gap: 1.5rem">
                      <div class="d-flex flex-column w-100">
                        <!-- ranting -->
                        <form @submit.prevent="sendCalification">
                          <div class="row" style="gap: 1rem">
                            <div
                              class="col d-flex flex-column justify-content-center align-items-center"
                            >
                              <h3
                                class="font-weight-bold"
                                style="font-size: 2.5rem"
                              >
                                {{ dataFilterDetails.calificacionCapacitacion }}
                              </h3>
                              <span
                                >{{
                                  dataFilterDetails.calificacionCapacitacion
                                }}/5</span
                              >
                              <b-form-rating
                                variant="warning"
                                inline
                                no-border
                                v-model="
                                  dataFilterDetails.calificacionCapacitacion
                                "
                                :disabled="
                                  !dataFilterDetails.planRegistroCapacitaciones
                                    .disponibilidad ||
                                  !dataFilterDetails.planRegistroCapacitaciones
                                    .estadoCapacitacion
                                "
                              ></b-form-rating>
                            </div>
                            <!-- comentario -->
                            <div
                              class="col-8 d-flex flex-column justify-content-center align-items-center"
                            >
                              <b-form-textarea
                                v-model="
                                  sendCalificationData.ComentarioAsistente
                                "
                                placeholder="Escriba un comentario..."
                                rows="3"
                                no-resize
                                max-rows="3"
                                size="sm"
                                minlength="10"
                                maxlength="255"
                                :disabled="
                                  !dataFilterDetails.planRegistroCapacitaciones
                                    .disponibilidad ||
                                  !dataFilterDetails.planRegistroCapacitaciones
                                    .estadoCapacitacion
                                "
                              ></b-form-textarea>
                            </div>
                          </div>
                          <!-- show mi comentario -->
                          <Transition name="fade">
                            <div
                              v-if="
                                dataFilterDetails.comentarioAsistente !== ''
                              "
                              class="d-flex align-items-center"
                            >
                              <div
                                v-b-tooltip.hover="{ variant: 'danger' }"
                                :title="`${persona.nombres} ${persona.apellidos}`"
                                class="font-weight-bold text-success border d-flex justify-content-center align-items-center mr-2"
                                style="
                                  width: 40px;
                                  height: 40px;
                                  border-radius: 50%;
                                  font-size: 1rem;
                                "
                              >
                                {{ persona.nombres[0] }}
                              </div>
                              <div>
                                <div
                                  class="d-flex align-items-center mb-1"
                                  style="gap: 1rem"
                                >
                                  <span class="font-weight-bold text-white-50"
                                    >Tú</span
                                  >
                                  <small
                                    class="font-weight-light text-white-50 font-italic"
                                    >{{
                                      dataFilterDetails.createdAt | timeSince
                                    }}</small
                                  >
                                </div>
                                <span
                                  v-b-tooltip.hover="{ variant: 'info' }"
                                  :title="`${dataFilterDetails.comentarioAsistente}`"
                                  class=""
                                  style="font-size: 1rem"
                                  >{{
                                    dataFilterDetails.comentarioAsistente
                                      .length > 35
                                      ? dataFilterDetails.comentarioAsistente.slice(
                                          0,
                                          35
                                        ) + "..."
                                      : dataFilterDetails.comentarioAsistente
                                  }}</span
                                >
                              </div>
                            </div>
                          </Transition>
                          <b-button
                            class="mt-3"
                            size="xl"
                            style="border-radius: 0 25px 0 25px"
                            type="submit"
                            block
                            :disabled="
                              !dataFilterDetails.planRegistroCapacitaciones
                                .disponibilidad ||
                              !dataFilterDetails.planRegistroCapacitaciones
                                .estadoCapacitacion
                            "
                          >
                            <i
                              v-if="isSendCalification"
                              class="fas fa-spinner fa-spin-pulse"
                            ></i>
                            {{
                              isSendCalification ? "Enviando..." : "Enviar"
                            }}</b-button
                          >
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- grafic progress confirmacion asistentes  -->
                  <div class="col-12 col-lg">
                    <b-card
                    class="banner-content"
                      style="
                        height: 100%;
                       
                      "
                    >
                      <div
                        class="d-flex flex-column justify-content-center align-items-center"
                        style="height: 100%"
                      >
                        <!-- component ProgressChart -->
                        <div
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <ProgressChart
                            :total="
                              dataFilterDetails.planRegistroCapacitaciones
                                .certificacion
                                ? 3
                                : 2
                            "
                            :confirmed="
                              handleProgress(
                                dataFilterDetails.calificacionCapacitacion,
                                dataFilterDetails.comentarioAsistente,
                                dataFilterDetails.estadoUploadCertificado
                              )
                            "
                          />
                          <h3 class="font-weight-bold mt-3 mb-1 text-uppercase">
                            Progreso General
                          </h3>
                          <span
                            class="font-weight-bold text-white-50"
                            style="font-size: 1rem"
                            >{{
                              (dataFilterDetails.planRegistroCapacitaciones
                                .certificacion
                                ? 3
                                : 2) ===
                              handleProgress(
                                dataFilterDetails.calificacionCapacitacion,
                                dataFilterDetails.comentarioAsistente,
                                dataFilterDetails.estadoUploadCertificado
                              )
                                ? "Completado"
                                : "Incompleto..."
                            }}
                          </span>
                        </div>
                        <!-- info icons -->
                        <div
                          class="d-flex justify-content-center mt-4"
                          style="gap: 2rem"
                        >
                          <!-- calificacion -->
                          <div
                            class="d-flex flex-column align-items-center"
                            style="gap: 0.7rem; width: 48px"
                          >
                            <div
                              v-b-tooltip.hover="{ variant: 'info' }"
                              title="Valoración"
                              class="d-flex justify-content-center rounded align-items-center bg-warning"
                              style="
                                width: 48px;
                                height: 48px;
                                box-shadow: 0px -1px 33px -8px #fe721c;
                              "
                            >
                              <i
                                class="fas fa-star fa-fade"
                                style="font-size: 1.2rem"
                              ></i>
                            </div>
                            <div
                              class="d-flex flex-column justify-content-center align-items-center"
                            >
                              <span style="font-size: 1.8rem; font-weight: bold"
                                >{{
                                  dataFilterDetails.calificacionCapacitacion
                                }}
                              </span>
                              <em
                                class="text-white-50 text-nowrap"
                                style="font-size: 0.8rem"
                                >valoración</em
                              >
                            </div>
                          </div>

                          <!-- divisor -->
                          <div
                            style="width: 1px; background-color: #102e5f"
                          ></div>
                          <!-- comentarios -->
                          <div
                            class="d-flex flex-column align-items-center"
                            style="gap: 0.7rem; width: 48px"
                          >
                            <div
                              v-b-tooltip.hover="{ variant: 'info' }"
                              title="Comentario"
                              class="d-flex justify-content-center rounded align-items-center bg-info"
                              style="
                                width: 48px;
                                height: 48px;
                                box-shadow: 0px -1px 33px -8px #4788ff;
                              "
                            >
                              <i
                                class="fas fa-comment fa-fade"
                                style="font-size: 1.2rem"
                              ></i>
                            </div>
                            <div
                              class="d-flex flex-column justify-content-center align-items-center"
                            >
                              <span
                                style="font-size: 1.8rem; font-weight: bold"
                              >
                                <i
                                  :class="`fas ${
                                    dataFilterDetails.comentarioAsistente !== ''
                                      ? 'fa-check'
                                      : 'fa-xmark text-danger'
                                  }`"
                                ></i>
                              </span>
                              <em
                                class="text-white-50"
                                style="font-size: 0.8rem"
                                >comentario</em
                              >
                            </div>
                          </div>

                          <!-- divisor -->
                          <div
                            v-if="
                              dataFilterDetails.planRegistroCapacitaciones
                                .certificacion
                            "
                            style="width: 1px; background-color: #102e5f"
                          ></div>

                          <!-- certificacion -->
                          <div
                            v-if="
                              dataFilterDetails.planRegistroCapacitaciones
                                .certificacion
                            "
                            class="d-flex flex-column align-items-center"
                            style="gap: 0.7rem; width: 48px"
                          >
                            <div
                              v-b-tooltip.hover="{ variant: 'info' }"
                              title="Certificado"
                              class="d-flex justify-content-center rounded align-items-center bg-success"
                              style="
                                width: 48px;
                                height: 48px;
                                box-shadow: 0px -1px 33px -8px #16a275;
                              "
                            >
                              <i
                                class="fas fa-file-arrow-up fa-fade"
                                style="font-size: 1.2rem"
                              ></i>
                            </div>
                            <div
                              class="d-flex flex-column justify-content-center align-items-center"
                            >
                              <span
                                style="font-size: 1.8rem; font-weight: bold"
                              >
                                <i
                                  :class="`fas ${
                                    dataFilterDetails.estadoUploadCertificado !==
                                    0
                                      ? 'fa-check'
                                      : 'fa-xmark text-danger'
                                  }`"
                                ></i>
                              </span>
                              <em
                                class="text-white-50"
                                style="font-size: 0.8rem"
                                >certificado</em
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </b-card>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
import moment_timezone from "moment-timezone";
import CardTable from "../../../components/cardTable/CardTable.vue";
import ProgressChart from "@/components/charts/ProgressChart.vue";
import FileUploadAndPreviewPdf from "./FileUploadAndPreviewPdf.vue";
const Swal = require("sweetalert2");
export default {
  name: "RegistroCapacitaciones",
  components: {
    CardTable,
    ProgressChart,
    FileUploadAndPreviewPdf,
  },
  data() {
    return {
      persona: {},
      dataTableInstance: null,
      ListaAreasCapacitacion: [],
      listaItems: [],
      modalShowDetailsCap: false,
      isConfirmAsistenciaMap: {},
      isSendCalification: null,
      dataFilterDetails: {
        id: "",
        capacitacionId: "",
        asistenteId: "",
        confirmacionAsistente: null,
        comentarioAsistente: "",
        calificacionCapacitacion: null,
        asistenciaReal: null,
        createdAt: null,
        estadoUploadCertificado: null,
        planRegistroCapacitaciones: {
          id: "",
          nombre: "",
          descripcion: "",
          areasCapacitacionId: "",
          fechaInicio: null,
          fechaFin: null,
          duracion: "",
          nombreInstructor: "",
          empresaCapacitadora: "",
          tipoEmpresaId: "",
          numeroDocumentoEmpresa: "",
          tipoDocumentoEmpresaId: "",
          numeroContrato: "",
          fechaContrato: null,
          modalidadCapacitacionId: "",
          costoTotal: "",
          listaAsistentesId: "",
          disponibilidad: null,
          motivoCancelacion: "",
          estadoCapacitacion: null,
          createdAt: null,
          responsableId: "",
          estadoUploadFiles: null,
          certificacion: null,
          tipoEmpresaCapacitacion: {
            id: "",
            descripcion: "",
            estado: null,
          },
          tipoDocumento: {
            id: "",
            descripcion: "",
            estado: null,
          },
          modalidadCapacitacion: {
            id: "",
            descripcion: "",
            estado: null,
          },
          responsable: {
            id: "",
            numeroDocumento: "",
            nombres: "",
            apellidos: "",
            direccion: "",
            telefonoFijo: "",
            telefonoMovil: "",
            contrasena: "",
            contrasenaReportes: null,
            correo: "",
            fechaNacimiento: null,
            fechaContratacion: null,
            ultimoIngreso: null,
            jefeDirectoId: "",
            estadoUsuario: null,
            estado: null,
            nivelReporte: "",
            empresaId: "",
            empresa: null,
            tipoDocumentoId: "",
            tipoDocumento: null,
            municipioId: "",
            municipio: null,
            departamentoPaisId: "",
            departamentoPais: null,
            paisId: "",
            pais: null,
            rolId: "",
            rol: null,
            estadoCivilId: "",
            estadoCivil: null,
            claveDinamica: "",
            correoNotificaciones: "",
            imagenPerfilUrl: null,
            tipoContrato: null,
            salarioIntegral: null,
            salario: null,
            tipoTrabajador: null,
            subtipoTrabajador: null,
            frecuenciaPago: null,
            altoRiesgo: null,
            auxilioTransporte: null,
            sabadoLaboral: null,
            nivelRiesgo: null,
            diasVacacionesAcumulados: null,
            metodoPago: null,
            banco: null,
            numeroCuenta: null,
            tipoCuenta: null,
            eps: null,
            cajaCompensacion: null,
            fondoPensiones: null,
            fondoCesantias: null,
          },
        },
        asistente: {
          id: "",
          numeroDocumento: "",
          nombres: "",
          apellidos: "",
          direccion: "",
          telefonoFijo: "",
          telefonoMovil: "",
          contrasena: "",
          contrasenaReportes: null,
          correo: "",
          fechaNacimiento: null,
          fechaContratacion: null,
          ultimoIngreso: null,
          jefeDirectoId: "",
          estadoUsuario: null,
          estado: null,
          nivelReporte: "",
          empresaId: "",
          empresa: null,
          tipoDocumentoId: "",
          tipoDocumento: null,
          municipioId: "",
          municipio: null,
          departamentoPaisId: "",
          departamentoPais: null,
          paisId: "",
          pais: null,
          rolId: "",
          rol: null,
          estadoCivilId: "",
          estadoCivil: null,
          claveDinamica: "",
          correoNotificaciones: "",
          imagenPerfilUrl: null,
          tipoContrato: null,
          salarioIntegral: null,
          salario: null,
          tipoTrabajador: null,
          subtipoTrabajador: null,
          frecuenciaPago: null,
          altoRiesgo: null,
          auxilioTransporte: null,
          sabadoLaboral: null,
          nivelRiesgo: null,
          diasVacacionesAcumulados: null,
          metodoPago: null,
          banco: null,
          numeroCuenta: null,
          tipoCuenta: null,
          eps: null,
          cajaCompensacion: null,
          fondoPensiones: null,
          fondoCesantias: null,
        },
      },
      sendCalificationData: {
        CapacitacionId: "",
        AsistenteId: "",
        ConfirmacionAsistente: 0,
        ComentarioAsistente: "",
        CalificacionCapacitacion: 0,
        AsistenciaReal: 0,
        CreatedAt: "",
        EstadoUploadCertificado: 0,
      },
      dataCards: [
        {
          title: "Finalizadas",
          value: () =>
            this.listaItems.filter(
              (progress) =>
                progress.planRegistroCapacitaciones.estadoCapacitacion &&
                progress.planRegistroCapacitaciones.disponibilidad
            ).length,
          iconName: "calendar-check",
          color: "rgba(22, 162, 117, 1)",
          tipo: "",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Finalizada").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Pendientes",
          value: () =>
            this.listaItems.filter(
              (progress) =>
                !progress.planRegistroCapacitaciones.estadoCapacitacion &&
                progress.planRegistroCapacitaciones.disponibilidad
            ).length,
          iconName: "clock",
          color: "rgba(254, 114, 28, 1)",
          tipo: "",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Pendiente").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Canceladas",
          value: () =>
            this.listaItems.filter(
              (progress) => !progress.planRegistroCapacitaciones.disponibilidad
            ).length,
          iconName: "ban",
          color: "rgba(223, 18, 46, 1)",
          tipo: "",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Cancelada").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Confirmadas",
          value: () =>
            this.listaItems.reduce(
              (total, progress) => total + progress.confirmacionAsistente,
              0
            ),
          iconName: "check-circle",
          color: "rgba(71, 136, 255, 1)",
          tipo: "",
          animated: "bounce",
          onActivate: () => console.log("Activated"),
          onDeactivate: () => console.log("Deactivate"),
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    await this.getAreaCapacitacion();
    core.index();
    if (window.$.fn.DataTable.isDataTable("#datatable_misCapacitaciones")) {
      window.$("#datatable_misCapacitaciones").DataTable().destroy();
    }
    this.dataTableInstance = window
      .$("#datatable_misCapacitaciones")
      .DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    this.$isLoading(false);
  },
  methods: {
    async sendCalification() {
      if (
        this.sendCalificationData.ComentarioAsistente ||
        this.dataFilterDetails.calificacionCapacitacion > 0
      ) {
        try {
          this.isSendCalification = true;
          console.log("enviado", this.dataFilterDetails);
          let res = await this.$store.dispatch("hl_post", {
            path: "PlanCapacitacionProgreso/AddCalificacionCapacitacion/",
            data: {
              ...this.sendCalificationData,
              CalificacionCapacitacion:
                this.dataFilterDetails.calificacionCapacitacion,
              CapacitacionId:
                this.dataFilterDetails.planRegistroCapacitaciones.id,
              AsistenteId: this.persona.id,
              CreatedAt: this.$moment
                .tz(new Date(), "America/Bogota")
                .format("YYYY-MM-DDTHH:mm:ss"),
            },
          });
          if (res.success) {
            this.dataFilterDetails.comentarioAsistente =
              this.sendCalificationData.ComentarioAsistente;
            this.dataFilterDetails.createdAt = this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm:ss");

            await this.getData();
            this.isSendCalification = false;
            Swal.fire({
              title: "Listo!",
              text: "Se ha registrado tú calificación.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
              cancelButtonText: "Cancelar",
            }).then(async (result) => {
              if (result.isConfirmed) {
                console.log("enviado!!");
                this.sendCalificationData.ComentarioAsistente = "";
                this.sendCalificationData.CalificacionCapacitacion = 0;
              }
            });
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      }
    },
    // Método para cambiar el estado de 'confirmacion asistencia'
    async toggleConfirmacion(rowIndex, item) {
      try {
        this.$set(this.isConfirmAsistenciaMap, item.id, true); // Establece el spinner en true para el elemento actual
        let res = await this.$store.dispatch("hl_get", {
          path:
            "PlanCapacitacionProgreso/ToogleConfirmacionAsistencia/" + item.id,
        });

        if (res) {
          this.$set(this.isConfirmAsistenciaMap, item.id, false); // Establece el spinner en false después de la operación exitosa
          // Redibuja solo la fila que cambió
          this.dataTableInstance.row(rowIndex).invalidate().draw();
          Swal.fire(
            "Listo!",
            "Se ha cambiado el estado correctamente.",
            "success"
          );
        }
      } catch (error) {
        console.log(error);
        this.$set(this.isConfirmAsistenciaMap, item.id, false); // Establece el spinner en false en caso de error
        Swal.fire("Error!", "Ha ocurrido un error.", "error");
        return null;
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PlanCapacitacionProgreso/ListByAsistenteId/" + this.persona.id,
        });
        console.log("list planCapacitacionProgreso...", res);
        if (res.length > 0) {
          this.listaItems = res.map((progress) => {
            progress.confirmacionAsistente =
              progress.confirmacionAsistente == 1 ? true : false;
            progress.planRegistroCapacitaciones.certificacion =
              progress.planRegistroCapacitaciones.certificacion == 1
                ? true
                : false;
            return progress;
          });
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },

    async getAreaCapacitacion() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "AreaCapacitacion/ListAreaCapacitacion",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.ListaAreasCapacitacion = res;
          ////console.log(res)
        } else {
          this.ListaAreasCapacitacion = [];
        }
      } catch (error) {
        this.ListaAreasCapacitacion = [];
        console.log("err", error);
      }
    },
    filterData(id) {
      this.modalShowDetailsCap = !this.modalShowDetailsCap;
      console.log(this.modalShowDetailsCap);

      const filteredData = this.listaItems.filter(
        (progress) => progress.id === id
      );

      console.log(filteredData);

      if (filteredData.length > 0) {
        const firstMatchingObject = filteredData[0];
        this.dataFilterDetails = {
          ...firstMatchingObject,
          areasCapacitacionId:
            firstMatchingObject.planRegistroCapacitaciones.areasCapacitacionId.split(
              ","
            ),
          areasCapacitacion: this.ListaAreasCapacitacion.filter((area) =>
            firstMatchingObject.planRegistroCapacitaciones.areasCapacitacionId.includes(
              area.id
            )
          ),
        };
      } else {
        this.dataFilterDetails = { ...this.dataFilterDetails };
      }
    },
    calcularTextoFecha() {
      const diferenciaDias = this.diferenciaDias;

      if (diferenciaDias > 0) {
        if (diferenciaDias === 1) {
          return "Falta 1 día";
        } else if (diferenciaDias < 7) {
          return `Faltan ${diferenciaDias} días`;
        } else if (diferenciaDias < 30) {
          const semanas = Math.floor(diferenciaDias / 7);
          return `Faltan ${semanas} semana${semanas === 1 ? "" : "s"}`;
        } else if (diferenciaDias < 365) {
          const meses = Math.floor(diferenciaDias / 30);
          return `Faltan ${meses} mes${meses === 1 ? "" : "es"}`;
        } else {
          const anios = Math.floor(diferenciaDias / 365);
          return `Faltan ${anios} año${anios === 1 ? "" : "s"}`;
        }
      } else if (diferenciaDias < 0) {
        const absDias = Math.abs(diferenciaDias);
        if (absDias === 1) {
          return "Hace 1 día";
        } else if (absDias < 7) {
          return `Hace ${absDias} días`;
        } else if (absDias < 30) {
          const semanas = Math.floor(absDias / 7);
          return `Hace ${semanas} semana${semanas === 1 ? "" : "s"}`;
        } else if (absDias < 365) {
          const meses = Math.floor(absDias / 30);
          return `Hace ${meses} mes${meses === 1 ? "" : "es"}`;
        } else {
          const anios = Math.floor(absDias / 365);
          return `Hace ${anios} año${anios === 1 ? "" : "s"}`;
        }
      } else {
        return "Hoy";
      }
    },
    handleProgress(valoracion, comentario, uploadCert) {
      let progress = 0;

      if (valoracion > 0) {
        progress++;
      }
      if (comentario !== "") {
        progress++;
      }
      if (uploadCert !== 0) {
        progress++;
      }

      return progress;
    },
  },
  computed: {
    diferenciaDias() {
      const fechaInicio = moment_timezone(
        this.dataFilterDetails.planRegistroCapacitaciones?.fechaInicio
      );
      const fechaActual = moment_timezone();

      const diferencia = fechaInicio.diff(fechaActual, "days");

      return diferencia;
    },
  },
  filters: {
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("es-ES");
      return formattedDate;
    },
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm A");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
